<template>
  <div>
    <h1>File</h1>
    
  </div>
</template>

<script>
export default {
  mounted() {
    const params = this.$route.params;
    console.log(params);
  },
  data() {
    return {
      file: null,
    };
  },
};
</script>
